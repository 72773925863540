import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {

  private contactUrl = environment.backEndUrl + '/sendMail';

  public contactForm = new FormGroup({
    firstname: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]),
    lastname: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]),
    email: new FormControl('', [Validators.email]),
    phone: new FormControl('', [Validators.minLength(10), Validators.maxLength(10)]),
    object: new FormControl('', [Validators.required]),
    content: new FormControl('')
  });

  constructor(private http: HttpClient) { }

  public ngOnInit(): void {
  }

  public sendMail(): void {
    this.http.post(this.contactUrl, this.contactForm.value)
    .subscribe(() => console.log('Email envoyé'));
  }

}
