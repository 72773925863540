import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrestationModalComponent } from './prestation-modal/prestation-modal.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    PrestationModalComponent
  ],
  imports: [
    CommonModule,
    NgbModalModule
  ],
  exports: [
    PrestationModalComponent
  ]
})
export class ModalsModule { }
