import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { faUser } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'sophrologist-website';
  faUser = faUser;

  constructor(private sanitizer: DomSanitizer, private http: HttpClient) {}

  urlAPI = "https://www.google.com/maps/embed/v1/place?key=AIzaSyAoEC6uc3QcY89RLkywhfj1EVi1u9GbrE4&q=28+Rue+Daniel,+33310+Lormont";
  MapsAPIKey = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlAPI);

}
