export interface PrestationInterface {
  id: number,
  title: string;
  description: string;
  duration: string;
  price: string;
}

export const Prestations: PrestationInterface[] = [
 {
    "id": 0,
    "title": 'Première consultation de sophrologie thérapeutique',
    "description": 'Prise de connaissance du client et de sa problématique avec mise en place d\'un protocole.',
    "duration": '1h30 à 2h',
    "price": '75€'
 },
 {
    "id": 1,
    "title": 'Suivi de sophrologie thérapeutique',
    "description": 'Ces séances suivent la prmière consultation afin d\'aider le client dans sa problématique.',
    "duration": '1h',
    "price": '60€'
 },
 {
    "id": 2,
    "title": 'Séance de sophrologie individuelle',
    "description": 'Ces scéances de sophrologie individuelle sont uniquement basés sur des exercices de respiration et de visualisations',
    "duration": '1h',
    "price": '50€'
 },
 {
    "id": 3,
    "title": 'Séance sophrologie de groupe (10 personnes maximum)',
    "description": 'Les séances de groupe ont pour but d\'aider les gens à lâcher prise, se détendre autravers d\'exercices de respirations',
    "duration": '1h',
    "price": '15€ / Personne'
 }
];