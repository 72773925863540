import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PrestationInterface } from 'src/app/interfaces/prestation-interface';

@Component({
  selector: 'app-prestation-modal',
  templateUrl: './prestation-modal.component.html',
  styleUrls: ['./prestation-modal.component.css'],
  host: {'class': 'prestation-modal'}
})
export class PrestationModalComponent implements OnInit {

  @Input() private prestation: PrestationInterface;

  constructor(public activeModal: NgbActiveModal) { }

  public ngOnInit(): void {
  }

}
