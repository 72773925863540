import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PrestationInterface, Prestations } from 'src/app/interfaces/prestation-interface';
import { PrestationModalComponent } from 'src/app/modals/prestation-modal/prestation-modal.component';

@Component({
  selector: 'app-prestation',
  templateUrl: './prestation.component.html',
  styleUrls: ['./prestation.component.css']
})
export class PrestationComponent implements OnInit {

  @Input() prestationID: number;
  public prestation: PrestationInterface;
  public prestationModal = PrestationModalComponent;

  constructor(private modalService: NgbModal) { }

  public ngOnInit(): void {
    this.prestation = this.getPrestationFromID(this.prestationID);
  }

  public getPrestationFromID(prestationID: number): PrestationInterface {
    return Prestations[prestationID];
  }


  public showPrestationModal($event): void {
    $event.stopPropagation();
    const modalRef = this.modalService.open(this.prestationModal, {centered: true, size: 'lg'});
    modalRef.componentInstance.prestation = this.prestation;
    modalRef.result.then((result) => {});
  }
}
